<template>
  <div>
    <b-overlay
      id="overlay-background"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <div>
        <b-card-actions
          title="Filters"
          action-collapse
          collapsed
        >
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Deposit Date"
                label-for="v-ref-no"
              >
                <div class="position-relative">
                  <flat-pickr
                    id="v-ref-no"
                    v-model="filters['branch_deposits.created_at']"
                    :config="{ mode: 'range', enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                    class="form-control"
                    placeholder="Search"
                  />
                  <div
                    v-if="filters['branch_deposits.created_at']"
                    class="flatpickr-cancel"
                    @click="clearDate('branch_deposits.created_at')"
                  >
                    <feather-icon icon="XIcon" />
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Deposit ID"
                label-for="v-ref-no"
              >
                <b-form-input
                  id="v-ref-no"
                  v-model="filters['branch_deposits.ref_no']"
                  placeholder="Search"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Warehouse"
                label-for="arehouse"
              >
                <v-select
                  v-model="filters['app_warehouses.id']"
                  :options="warehouse"
                  :reduce="option => option.id"
                  label="name"
                  placeholder="Select Warehouse"
                >
                  <template v-slot:option="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Apply and Clear Filters -->
          <b-row class="mt-1">
            <b-col>
              <filter-buttons
                :filter-data="filters"
                :apply-filters="applyFilters"
                :clear-filters="clearFilters"
              />
            </b-col>
          </b-row>
        </b-card-actions>
      </div>
      <div>
        <b-card
          no-body
        >
          <div
            class="m-2"
          >
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <div>
                  <ReportTableButtons
                    :items="items"
                    :json_fieldz="json_fields"
                    :name="`${downloadReportName} - Page ${page}.xls`"
                    :bulkname="`${downloadReportName}.xls`"
                    :fetch="fetchBranchProgressNoPagination"
                    @refresh="fetchBranchProgress"
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            show-empty
            sticky-header="100vh"
          >
            <template #empty>
              <TableDataFetching
                :rows="items"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(deposit_date)="data">
              {{ __dateTimeFormatter(data.value) }}
            </template>
            <template #cell(order_count)="data">
              {{ __numberWithCommas(data.value) }}
            </template>
            <template #cell(total_delivery_charge)="data">
              {{ __numberWithCommas(data.value, true) }}
            </template>
            <template #cell(warehouse_type)="data">
              <b-badge :variant="`${data.value === 1 ? 'light-success' : 'light-primary'}`">
                {{ data.value === 1 ? 'Agent' : 'Branch' }}
              </b-badge>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                md="3"
                class="d-flex align-items-center justify-content-sm-start"
              >
                <label class="mr-1">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-1 width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                md="9"
                class="d-flex align-items-center justify-content-sm-end"
              >
                <span class="text-muted pagination-text  mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :total-rows="(meta.total)? meta.total : 0"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BRow,
  BTable,
  VBModal,
  BOverlay,
  BPagination,
  VBTooltip,
  BBadge,

} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Filter from '@core/mixins/filter'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import FilterButtons from '@/components/Filter/ReportFilterButtons.vue'

const ReportRepository = RepositoryFactory.get('report')
const ResourceRepository = RepositoryFactory.get('resource')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCardActions,
    vSelect,
    flatPickr,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BPagination,
    BFormInput,
    BBadge,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      filters: {
        'branch_deposits.created_at': `${new Date(new Date().setHours(0, 0, 1, 0)).toISOString()} to ${new Date(new Date().setHours(23, 59, 59, 0)).toISOString()}`,
      },
      tableData: [],
      dataLoading: false,
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50, 100, 200],
      totalRows: 1,
      page: 1,
      meta: {},
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      warehouse: [],
      fields: [
        {
          key: 'deposit_date',
          label: 'Deposit Date',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'deposit_id',
          label: 'Deposit ID',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'warehouse_name',
          label: 'Warehouse',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'ref_no',
          label: 'Ref No',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'warehouse_type',
          label: 'Type',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'order_count',
          label: 'Order Count',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          key: 'total_delivery_charge',
          label: 'Delivery Charge',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
        },
      ],
      json_fields: {
        'DEPOSIT DATE': {
          field: 'deposit_date',
          // eslint-disable-next-line no-underscore-dangle
          callback: value => this.__dateTimeFormatter(value),
        },
        'DEPOSIT ID': {
          field: 'deposit_id',
          callback: value => `"${value}"`,
        },
        WAREHOUSE: {
          field: 'warehouse_name',
          callback: value => `"${value}"`,
        },
        'REF NO': {
          field: 'ref_no',
          callback: value => `"${value}"`,
        },
        TYPE: {
          field: 'warehouse_type',
          callback: value => (value === 1 ? 'Agent' : 'Branch'),
        },
        'ORDER COUNT': {
          field: 'order_count',
          callback: value => `"${value}"`,
        },
        'DELIVERY CHARGE': {
          field: 'total_delivery_charge',
          callback: value => `"${value}"`,
        },
      },
      currentDate: '',
      todayFilterQuery: '',
      downloadReportName: 'Branch-Progress-Report',
      items: [],
      expenseOptions: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchBranchProgress()
      },
    },
    deep: true,
    page() {
      this.fetchBranchProgress()
    },
    perPage() {
      this.fetchBranchProgress()
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.fetchBranchProgress()
    this.readDataforWarehouse()
  },
  methods: {
    async fetchBranchProgress() {
      this.dataLoading = true
      this.items = []
      try {
        const { data } = (await ReportRepository.getBranchProgressReport(this.page, this.filterQuery, this.perPage))
        // fiter the default date or selected date for table data and meta data
        this.items = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchBranchProgressNoPagination() {
      try {
        const { data } = (await ReportRepository.getBranchProgressReportNoPagiantion(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async readDataforWarehouse() {
      try {
        const { data } = (await ResourceRepository.getBranchListDropdown()).data
        this.warehouse = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    handleChangePage(page) {
      this.page = page
      this.fetchBranchProgress()
    },
    applyFilters() {
      this.fetchBranchProgress()
    },
    clearFilters() {
      this.filters = {
        'branch_deposits.created_at': `${new Date(new Date().setHours(0, 0, 1, 0)).toISOString()} to ${new Date(new Date().setHours(23, 59, 59, 0)).toISOString()}`,
      }
      this.$nextTick(() => {
        this.fetchBranchProgress()
      })
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select';
@import '../../../@core/scss/vue/libs/vue-flatpicker';
</style>
